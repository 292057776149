/* changed styling to fix render issue on menu collapse */
.ant-layout-sider {
	background: #ffffff;
}

/* changed styling to be more readable */
.ant-menu-submenu-title {
	line-height: 48px !important;

	height: 48px !important;
	margin: 0 0 -1px 0 !important; /* border hack */

	background: rgba(6, 127, 91, 0.2);
}

.ant-menu-sub.ant-menu-inline {
	background-color: white;
}

.ant-layout-sider-trigger {
	background: var(--antd-wave-shadow-color);
}

/* for some reason ant design is stupid and changes this in the collapsed state, causing the icon to not be vertically centered*/
.ant-menu-item .anticon {
	line-height: 0 !important;
}

.datasets .ant-table-tbody > tr.ant-table-row-selected > td {
	background-color: #cbd9d2;
}

.sidebar .ant-spin-nested-loading, .sidebar .ant-spin-container {
	height: 100%;
}

.ant-menu-title-content {
	flex: none !important;
}