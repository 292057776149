path.arc {
	cursor: move;

	fill: #ffffff;
}

.node {
	font-size: 10px;
	font-weight: 600;

	cursor: pointer;
}

.node:hover {
	fill: black;
}

.link {
	pointer-events: none;

	fill: none;
	stroke: #444444;
	stroke-linecap: round;
	stroke-opacity: 0.4;
}

.link-source, .link-target {
	stroke-opacity: 1;
	stroke-width: 2px;
}

.node-target {
	fill: #e67e29 !important;
}

.link-source {
	stroke: #e67e29;
}

.node-source {
	fill: #1890ff;
}

.link-target {
	stroke: #1890ff;
}

svg.fastRender {
	shape-rendering: optimizeSpeed;
	text-rendering: optimizeSpeed;
}

.capitalize {
	text-transform: capitalize;
}
