.left, .right {
    width: 60%;
    margin: 50px;
}

.left {
    margin-left: 50px;
    margin-right: auto;
}

.right {
    margin-right: 50px;
    margin-left: auto;
}

@media screen and (max-width: 1000px) {
    .left, .right {
        width: 80%;
        margin: auto;
        margin-top: 50px;
    }

    .container {
        align-items: center;
    }
}